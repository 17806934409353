"use client";

import { HomeLayout } from "@/components/Layout/HomeLayout";
import { inter } from "@/theme/fonts";
import { useLingui } from "@lingui/react";
import { ActionIcon, Box, Button, Container, Flex, Group, Stack, Text, Title, UnstyledButton } from "@mantine/core";
import { IconArrowUpRight, IconBrandDiscordFilled, IconBrandMedium, IconBrandX } from "@tabler/icons-react";
import Image from "next/image";
import Link from "next/link";
export default function Home() {
  const {
    i18n
  } = useLingui();
  return <HomeLayout>
			<Box component="video" pos="absolute" inset={0} w="100%" h="100%" loop autoPlay muted playsInline style={{
      objectFit: "cover",
      zIndex: -1
    }} visibleFrom="sm">
				<source src="/assets/media/output.webm" type="video/webm" />
				<track kind="captions" srcLang="en" label="English" default />
				Your browser does not support the video tag.
			</Box>
			<Flex h="100%" w="100%" align="center" justify="center">
				<Container fluid mx="auto" w="100%">
					<Stack px={{
          lg: 20,
          xl: 160
        }}>
						<Flex justify="space-between" direction={{
            base: "column",
            md: "row"
          }} align="center">
							<Stack gap={20} visibleFrom="md">
								<Box maw={318} c="white">
									<Title tt="uppercase" fz={36}>
										{i18n._("Be Part Of Our Community")}
									</Title>
									<Text mt={10} fw="bold">
										{i18n._("Explore the vibrant tapestry of our amazing community, where connections thrive and stories unfold.")}
									</Text>
								</Box>
								<Stack gap={0}>
									<Text c="white" size="sm" fw="bold">
										{i18n._("Community")}
									</Text>
									<Group gap={0}>
										<ActionIcon component={Link} href="https://x.com/Yeve_fi" size={60} variant="filled" color="rgba(255, 255, 255, 1)" radius="xl" c="black" style={{
                    zIndex: 1
                  }}>
											<IconBrandX size={30} />
										</ActionIcon>
										<ActionIcon component={Link} href="https://discord.gg/ZDyXeyjGc7" size={60} variant="filled" color="rgba(255, 255, 255, 1)" radius="xl" ml={-10} style={{
                    zIndex: 2,
                    boxShadow: "0px 4px 4.5px 0px rgba(0, 0, 0, 0.25)"
                  }} c="black">
											<IconBrandDiscordFilled size={30} />
										</ActionIcon>
										<ActionIcon component={Link} href="https://medium.com" size={60} variant="filled" color="rgba(255, 255, 255, 1)" radius="xl" ml={-10} c="black" style={{
                    zIndex: 3,
                    boxShadow: "0px 4px 4.5px 0px rgba(0, 0, 0, 0.25)"
                  }}>
											<IconBrandMedium size={30} />
										</ActionIcon>
										<ActionIcon component={Link} href="https://medium.com" size={80} radius="xl" variant="transparent" c="rgba(255, 255, 255, 1)" ml={-10}>
											<IconArrowUpRight size={80} stroke={1} />
										</ActionIcon>
									</Group>
								</Stack>
							</Stack>

							<Box component="video" mt={{
              base: -20,
              md: "auto"
            }} maw={{
              base: 500,
              md: 400,
              lg: 500
            }} w="110%" loop autoPlay muted playsInline hiddenFrom="sm" pos="relative" style={{
              zIndex: -1
            }}>
								<source src="/assets/media/output.webm" type="video/webm" />
								<track kind="captions" srcLang="en" label="English" default />
								Your browser does not support the video tag.
							</Box>

							<Stack gap={20} mt={80} miw={330} visibleFrom="md">
								<Box maw={318} c="white">
									<Title tt="uppercase" fz={36} c="rgba(255, 255, 255, 1)">
										{i18n._("BUILT ON")}
									</Title>
									<Button variant="transparent" leftSection={<Image src="/assets/tokens/sol-rounded.svg" width={48} height={48} alt="Solana" />} rightSection={<Image src="/assets/icons/star.svg" width={32} height={32} alt="star" />} c="rgba(255, 255, 255, 1)" size="xl" tt="uppercase" ml={-20} fz={34} fw="bold">
										{i18n._("Solana")}
									</Button>
									<Text mt={10} fw="bold" c="rgba(255, 255, 255, 1)">
										{i18n._("At the forefront of innovation harnessing the power and scalability of Solana architecture. Providing a cutting-edge solution for efficient cryptocurrency transactions.")}
									</Text>
									<Button miw={160} tt="uppercase" color="purple.0" size="lg" my={20} c="purple.17" fz={16} component={Link} href="https://docs.yeve.fi/">
										{i18n._("Read Docs")}
									</Button>
								</Box>
							</Stack>
						</Flex>

						<Flex justify="space-between" align="center" mt={{
            base: -60,
            md: 20
          }} direction={{
            base: "column",
            md: "row"
          }}>
							<Group maw={{
              base: "none",
              md: 640
            }}>
								<Text fz={{
                base: 40,
                md: 48
              }} lh={1} c="gray.1" fw={600} className={inter.className} ta={{
                base: "center",
                md: "left"
              }}>
									<Box component={Image} src={"/assets/icons/star-purple.svg"} width={48} height={48} alt="Star" ml={10} mr={5} pt={10} />
									{i18n._("The future of deep liquidity and ve3 simplicity on Solana")}
									<Box component={Image} src={"/assets/icons/star.svg"} width={48} height={48} alt="Star" ml={10} pt={10} />
								</Text>
							</Group>

							<Stack gap={5} hiddenFrom="md" mt={20}>
								<Group justify="center">
									<Title tt="uppercase" fz={{
                  base: 18,
                  sm: 36
                }} c="rgba(255, 255, 255, 1)">
										{i18n._("BUILT ON")}
									</Title>
									<Button variant="transparent" leftSection={<Image src="/assets/tokens/sol-rounded.svg" width={32} height={32} alt="Solana" />} rightSection={<Image src="/assets/icons/star.svg" width={32} height={32} alt="star" />} c="rgba(255, 255, 255, 1)" size="xl" tt="uppercase" ml={-20} fz={{
                  base: 18,
                  sm: 36
                }} fw="bold">
										{i18n._("Solana")}
									</Button>
								</Group>
								<Text my={10} fw="bold" c="rgba(255, 255, 255, 1)">
									{i18n._("At the forefront of innovation harnessing the power and scalability of Solana architecture. Providing a cutting-edge solution for efficient cryptocurrency transactions.")}
								</Text>
								<Flex direction={{
                base: "column",
                sm: "row"
              }} w="100%" justify="center" align="center" gap={10}>
									<Button miw={160} tt="uppercase" color="purple.0" size="lg" c="purple.17" fz={16} fullWidth component={Link} href="https://docs.yeve.fi/">
										{i18n._("Read Docs")}
									</Button>
									<Button component={Link} href="/swap" color="purple.9" size="lg" fullWidth miw={200}>
										{i18n._("Launch App")}
									</Button>
								</Flex>
							</Stack>

							<Stack gap={5} visibleFrom="md">
								<Group gap={10}>
									<ActionIcon color="purple.10" variant="fill" size="lg" radius="xl">
										<Image src="/assets/icons/star.svg" width={24} height={24} alt="Star" />
									</ActionIcon>
									<Button color="gray.18" tt="uppercase">
										{i18n._("Swap")}
									</Button>
									<Button color="gray.18" tt="uppercase">
										{i18n._("Earn")}
									</Button>
									<UnstyledButton pt={10}>
										<Image src="/assets/icons/arrow-right.svg" width={54} height={23} alt="Arrow" />
									</UnstyledButton>
								</Group>

								<Group gap={10}>
									<Button color="gray.18" tt="uppercase">
										{i18n._("Vote")}
									</Button>
									<Button color="gray.18" tt="uppercase">
										{i18n._("Rewards")}
									</Button>
									<Button color="gray.18" tt="uppercase">
										{i18n._("Bribes")}
									</Button>
									<UnstyledButton size="lg">
										<Image src="/assets/icons/star.svg" width={24} height={24} alt="Star" />
									</UnstyledButton>
								</Group>
							</Stack>
						</Flex>

						<Flex hiddenFrom="md" direction={{
            base: "column",
            md: "row"
          }} justify="space-between" align="center" gap={20}>
							<Box c="white" ta={{
              base: "center",
              sm: "left"
            }}>
								<Title tt="uppercase" fz={36}>
									{i18n._("Be Part Of Our Community")}
								</Title>
								<Text mt={10} fw="bold">
									{i18n._("Explore the vibrant tapestry of our amazing community, where connections thrive and stories unfold.")}
								</Text>
							</Box>
							<Stack gap={0}>
								<Text c="white" size="sm" fw="bold" ta={{
                base: "center",
                md: "left"
              }}>
									{i18n._("Community")}
								</Text>
								<Group gap={0}>
									<ActionIcon component={Link} href="https://twitter.com" size={60} variant="filled" color="rgba(255, 255, 255, 1)" radius="xl" c="black" style={{
                  zIndex: 1
                }}>
										<IconBrandX size={30} />
									</ActionIcon>
									<ActionIcon component={Link} href="https://discord.gg/ZDyXeyjGc7" size={60} variant="filled" color="rgba(255, 255, 255, 1)" radius="xl" ml={-10} style={{
                  zIndex: 2,
                  boxShadow: "0px 4px 4.5px 0px rgba(0, 0, 0, 0.25)"
                }} c="black">
										<IconBrandDiscordFilled size={30} />
									</ActionIcon>
									<ActionIcon component={Link} href="https://medium.com" size={60} variant="filled" color="rgba(255, 255, 255, 1)" radius="xl" ml={-10} c="black" style={{
                  zIndex: 3,
                  boxShadow: "0px 4px 4.5px 0px rgba(0, 0, 0, 0.25)"
                }}>
										<IconBrandMedium size={30} />
									</ActionIcon>
									<ActionIcon component={Link} href="https://medium.com" size={80} radius="xl" variant="transparent" c="rgba(255, 255, 255, 1)" ml={-10}>
										<IconArrowUpRight size={80} stroke={1} />
									</ActionIcon>
								</Group>
							</Stack>
						</Flex>
					</Stack>
				</Container>
			</Flex>
		</HomeLayout>;
}