import { useLingui } from "@lingui/react";
import { Flex, List, Text } from "@mantine/core";
import { useMemo } from "react";
import classes from "./Footer.module.css";
import { LinkItem, type LinkItemProps } from "./LinkItem";
export const Footer: React.FC = () => {
  const {
    i18n
  } = useLingui();
  const links: LinkItemProps[] = useMemo(() => [{
    title: i18n._("Documentation"),
    href: "https://docs.yeve.fi/"
  }, {
    title: i18n._("Privacy Policy"),
    href: "/privacy"
  }, {
    title: i18n._("Terms of Service"),
    href: "/term"
  }], [i18n]);
  return <Flex align="center" justify="space-between" gap={10} direction={{
    base: "column",
    md: "row"
  }}>
			<Text c="gray.4" size="sm" ta="center">
				{new Date().getFullYear()} © {i18n._("All rights reserved")}
			</Text>
			<List withPadding c="dimmed" center={true} classNames={{
      root: classes.link,
      itemLabel: classes.linkItem
    }}>
				{links.map(link => <List.Item key={link.title}>
						<LinkItem {...link} />
					</List.Item>)}
			</List>
		</Flex>;
};