import { Anchor } from '@mantine/core';
import Link from 'next/link';
import classes from './Footer.module.css';
export interface LinkItemProps {
  title: string;
  href: string;
}
export const LinkItem: React.FC<LinkItemProps> = ({
  href,
  title
}) => {
  return <Anchor size="sm" c="dimmed" underline="never" component={Link} href={href} className={classes.linkAnchor}>
      {title}
    </Anchor>;
};