import { Carousel } from '@mantine/carousel';
import { Box, Image } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
export const slides = [{
  image: '/assets/partners/chainlink.svg',
  title: 'Chainlink',
  href: '#'
}, {
  image: '/assets/partners/wormhole.svg',
  title: 'Wormhole',
  href: '#'
}, {
  image: '/assets/partners/pyth.svg',
  title: 'Pyth',
  href: '#'
}];
export const PartnerSlide: React.FC = () => {
  const isMobile = useMediaQuery('(max-width: 48em)');
  return <Box bg="#050505" opacity="0.9" pos="fixed" w="100%" bottom={{
    base: 82,
    md: 52
  }} py={20} style={{
    zIndex: 901
  }}>
      <Carousel withControls={false} height="100%" slideSize={{
      base: '50%',
      sm: '33.333333%'
    }} loop align="start" dragFree={!!isMobile} draggable={!!isMobile} styles={{
      viewport: {
        maxWidth: 800,
        margin: '0 auto'
      }
    }}>
        {slides.map(slide => <Carousel.Slide key={slide.title} miw={200} style={{
        display: 'flex',
        justifyContent: 'center'
      }}>
            <Image src={slide.image} alt={slide.title} height={30} w="auto" my="auto" />
          </Carousel.Slide>)}
      </Carousel>
    </Box>;
};